import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'

interface AppState {
  chartIsRendering: boolean
  graphDataStatus: {
    graph1IsFetching: boolean
    graph2IsFetching: boolean
  }
}

const initialState: AppState = {
  chartIsRendering: false,
  graphDataStatus: {
    graph1IsFetching: false,
    graph2IsFetching: false,
  },
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeGraphIsFetching(state, action) {
      const { graphNr, isFetching } = action.payload
      if (graphNr === 1) {
        state.graphDataStatus.graph1IsFetching = isFetching
      }
      if (graphNr === 2) {
        state.graphDataStatus.graph2IsFetching = isFetching
      }
    },
    changeChartIsRendering(state, action) {
      state.chartIsRendering = action.payload
    },
  },
})

export const appReducer = appSlice.reducer
export const analyticsGraphDataStatus = (state: RootState) => state.analytics.graphDataStatus
export const { changeGraphIsFetching, changeChartIsRendering } = appSlice.actions
